

.takliflar{
    margin-top:80px;
    margin-bottom:80px;
}
@media(max-width:450px){
    .tilefor{
        margin-left: 30px !important;
        line-height: 35px;
        font-size: 25px;
    }
    .takliflar{
        margin-top:0px;
        margin-bottom:30px;
    }
}