@media (min-width:1367px){
    .divyozish{
        width:1400px;
        margin: 0 auto;
        
    }
}
.paragref{
    font-weight: 600;
    margin-left: 10px;
}