.DayPicker-Day{
    background-color:#326a32 !important;
    color:#fff !important;
}
.DayPicker-Day.DayPicker-Day--selected{
    background-color: rgb(160, 0, 0) !important;
}
.span_sirkle, .span_sirkle2{
    display: inline-block;
    border-radius: 50%;
    width: 30px;
    height:30px;
    background: #326a32;
}
.span_sirkle2{
    background-color: rgb(160, 0, 0) !important;
}

.divdisplay{
    display: flex;
    align-items: center;
    justify-content: space-between;    
    margin-top:4px;
}
.divbg{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    font-size: 13px;
    width: 150px;
    margin: 0 auto;
}
.oylikcenter{
    text-align: center;
}