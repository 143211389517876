.ant-upload-list.ant-upload-list-text{
    display: none !important;
}
/* .sc-giImIA.eBOkqV.cursor-pointer{
    display:flex !important;
    flex-direction:column-reverse !important;
} */
.image_cropped{
    width:250px;
    height:250px;
    border-radius:50%;
    margin: 0 auto;
    object-fit: cover;
}
.image_cropped_rect{
    width:250px;
    height:250px;
    border-radius:4px;
    margin: 0 auto;
    object-fit: cover;
}
.cropper_container{
    display:flex !important;
    flex-direction:column !important;
}
.icon_wrapper{
    display:flex !important;
    align-items:center !important;
    justify-content:center !important;
}
.icb{
    width:80px; 
    height:35px; 
    margin-top: 10px;
    line-height: 50px;
    text-align: center;
    cursor:pointer !important;
}
.del_icon{
    color:rgb(241, 71, 71);
}
.add_icon{
    position:relative;
    /* top:10px; */
    color:#326a32;
    /* border:1px solid #326a32; */
}
.icon_sf{
    position:relative;
    top:-4px;
}
.image_no_user{
    width:250px;
    height:250px;
    object-fit:cover;
    border-radius:50%;
    margin: 0 auto;
    /* background-color:red; */
}
@media (max-width: 450px){
    .image_no_user, .image_cropped{
        width:200px;
        height:200px;
    }
}