*{
  outline:none !important;
  font-family: Montserrat;
}
/* form {
    padding-top: 80px;
    max-width: 340px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
  }
   */
  /* Input style */
  .input-container {
    position: relative;
    display: flex;
    flex-direction: column;
  }
  
  .input-container:focus-within label {
    transform: translate(0, 12px) scale(0.8);
    color: #326a32;
  }
  
  .input-container .filled {
    transform: translate(0, 12px) scale(0.8);
  }
  
  .input-container label {
    position: absolute;
    pointer-events: none;
    transform: translate(0, 23px) scale(1);
    transform-origin: top left;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    /* color: #6f81a5; */
    font-size: 16px;
    line-height: 1;
    left: 16px;
  }
  
  .input-container input {
    height: 64px;
    border-radius: 4px;
    border: none;
    padding: 24px 16px 4px 16px;
    font-size: 16px;
    line-height: 1;
    outline: none !important;
    box-shadow: none;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    background:#FAFCFA;
  }
  