.css-2b097c-container{
    width:100%;
    outline: none !important;
}
.css-yk16xz-control{
    border:none !important;
    box-shadow:none !important;
    background-color:transparent !important;
}
.css-1pahdxg-control{
    border:none !important;
    box-shadow:none !important;
    background-color:transparent !important;
}
.css-1okebmr-indicatorSeparator{
    display:none !important;
}
.progressTitle{
    max-width: 66% !important;
}
/* .iFJeRb {
    width: 100% !important;
} */
/* @media  */
@media (max-width: 1180px){
    #searchWrapper{
        width: 100% !important
    }
    .WrapperInfoF2{
        margin-left: 55px !important;
    }
    .btnChooseGids{
        margin-right: 50px !important;
    }
    .closebtn{
        width: 50px;
        left: 80%;
        position: relative;
    }
    .videoPlayer{
        width: 100% !important;
    }
    .sectionPlayer{
        height: auto !important;
    }
    .etdsOH{
        width: 100% !important;
    }
    .DayPicker{
        width: 100% !important;
    }
    .ContainerImage{
        width: 100% !important;
    }
 
    
}
@media screen and (max-width: 960px) {
    .DayPicker{
        width: 300px !important;
    }
    
}
@media screen and (max-width: 750px) {
    .wrap_cal{
        width: 100% !important;
    }
    
}

@media only screen and (max-width: 707px){
    .selects{
        width:100% !important;
    }
   
    .videoPlayer{
        width: 100% !important;
    }
    .sectionPlayer{
        height: auto !important;
    }
    .closebtn{
        width: 40px;
        left: 75%;
        position: relative;
    }
}
@media screen and (max-width: 720px){
    .WrapperInfoF2{
        margin-left: 55px !important;
     
    }
    .btnChooseGids{
        margin-right: 50px !important;
        padding: 12px 16px !important;
    }    
}
@media screen and (max-width: 600px) {
    .ant-image{
        position: relative;
        top: -30px !important;
        left: 20px !important;
    }
    .btnChooseGids{
        margin-right: 0px !important;
        padding: 14px 32px !important;
    }
       .WrapperInfoF2{
        width: 50% !important;
        margin-left: 0px !important;
    }
    .c_per_day{
        width: auto !important;
    }
    .AccContainer{
        display: flex;
        flex-direction: row !important;
    }
    .AccImgBlock{
        width: 50% !important;
    }
    
}
@media screen and (max-width: 576px) {
    .RegisterError{
        width: 95% !important;
    }
    /* .geobLB {
        width: 100% !important;
    } */
   
    .MuiGrid-grid-md-2{
        max-width: 58.333333% !important;
    }
    .empty_title{
        width: auto !important;
    }
    .brifings{
        flex-direction: column !important;
    }
    .MuiGrid-spacing-xs-5{
        width: 100% !important;
        margin: -20px 0 !important;
    }
    .MuiGrid-spacing-xs-5 > .MuiGrid-item{
        padding: 20px 0 !important;
    }
    .CostStoreSave{
        width: auto !important;
    }
    .DayPicker{
        width: 300px !important;
    }
    .MuiGrid-spacing-xs-3{
        width: 100% !important;
        margin: 0 auto !important;
    }
    .MuiGrid-spacing-xs-3 > .MuiGrid-item{
        padding: 0px !important;
    }
    .wrap_cal{
        width: 100% !important;
    }
    .writerCAT{
        margin-left: 0px !important;
    }
    .kZEBGz .mualif{
        width: auto !important;
    }
    .PkService{
        width: 100% !important;
    }
    @media screen and (max-width: 400px) {
        .infoMain{
            width: 75% !important;
        }
        
    }
    
    
}
/* .css-yk16xz-control */