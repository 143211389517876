.text-div {
    text-align: justify !important;
}
.msa{
    padding: 30px !important;
    padding-bottom: 38px !important;
}
@media(max-width:450px){
    .msa{
        padding: 0px !important;
        padding-bottom: 38px !important;
    }
}